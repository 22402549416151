  export const HeroRoles = {
    roles: [
      "Expertise that Matters",
      "Innovation at its Core",
      "Partnerships Not Projects",
      " Building The Extraordinary",
    ]
};

  export const skills = [
    {
      title: "Frontend",
      skills: [
        {
          name: "React Js",
          image:
            "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
        },
        {
          name: "Redux",
          image:
            "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
        },
        {
          name: "HTML",
          image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
        },
        {
          name: "CSS",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
        },
        {
          name: "JavaScript",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
        },
        {
          name: "Bootstrap",
          image:
            "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
        },
      ],
    },
    {
        title: "Backend",
        skills: [
          {
            name: "Node Js",
            image: "https://nodejs.org/static/images/logo.svg",
          },  
          {
            name: "Python",
            image:
              "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
          },
          {
            name: "MySQL",
            image:
              "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
          },
          {
            name: "Postgresql",
            image: "https://www.postgresql.org/media/img/about/press/elephant.png",
          },
          {
            name: "MongoDB",
            image:
              "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
          },
          {
            name: "Firebase",
            image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
          },
        ],
      },
      {
        title: "Misc",
        skills: [
          {
            name: "Git",
            image:
              "https://camo.githubusercontent.com/fbfcb9e3dc648adc93bef37c718db16c52f617ad055a26de6dc3c21865c3321d/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6769742d73636d2f6769742d73636d2d69636f6e2e737667",
          },
          {
            name: "GitHub",
            image:
              "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
          },
          {
            name: "Docker",
            image:
              "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
          },

          {
            name: "Kubernetes",
            image:
              "https://raw.githubusercontent.com/devicons/devicon/master/icons/kubernetes/kubernetes-plain-wordmark.svg",
          },

          {
            name: "VS Code",
            image:
              "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
          },

          {
            name: "AWS ",
            image:
              " https://raw.githubusercontent.com/devicons/devicon/master/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
          }

        ],

      },
    ];


    export const services = [
      {
        id: 0,
        title: "Web Development",
        description:
          "Crafting dynamic and interactive websites tailored to your unique requirements.",
        image:
          "https://cdn.pixabay.com/photo/2015/12/04/14/05/code-1076536_1280.jpg",
      },
      {
        id: 1,
        title: "E-Commerce Solutions",
        description:
          "Empowering businesses with comprehensive online platforms for seamless transactions & market reach.",
        image:
          "https://www.geckoboard.com/uploads/Ecommerce-KPI-dashboard.png",
      },
      {
        id: 2,
        title: "Maintenance & Support",
        description:
          "Ensuring the continuous operation and optimal performance of your digital platforms through reliable maintenance.",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD2Wp490kAu_08NPSONWL0Yaa0cSjTrLFHJOMrfxo9iLEXk3-12FbStsWsXM7j_UhZhm8&usqp=CAU",
      },
      {
        id: 3,
        title: "Consulting",
        description:
          "Providing strategic insights and expert guidance to optimize your online presence and achieve your business objectives.",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuazTwy24tmpymJVTEfzyTU3UK_txQGUK-DA&usqp=CAU",
      },
    ];
    

    export const actioncall = [
      {
        id: 0,
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSACp64QgGzZE00erymcurRcAOMRBauH_uK6Q&usqp=CAU",
        role: "Expertise that Matters",
        desc: "With years of experience and a diverse portfolio of successful projects, we have the expertise to transform your digital presence into a powerful asset for your business.",
      },
      {
        id: 1,
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRseUAX2HNSsiusth3YVRVToEyqJO-FU_6jXg&usqp=CAU",
        role: "Innovation at its Core",
        desc: "Staying at the forefront of technology is our commitment. We embrace emerging trends, ensuring your web solution is not just current, but future-proof.",
      },
      {
        id: 2,
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt8QrmX7U8bvDVRq-_6oH5JP-_IGrbooKpJw&usqp=CAU",
        role: "Partnerships Not Projects",
        desc: "We believe in long-lasting relationships. Our team is dedicated to providing ongoing support and optimization, ensuring your web presence evolves with your business.",
      },
      {
        id: 3,
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6XnYbcZ9oDAAnLz8WgJAOWH4JLKF6TeVomQ&usqp=CAU",
        role: "Let's Build Something Extraordinary Together",
        desc: "Whether you're a startup, a growing business, or an established enterprise, IF Technologies & Solutions is poised to be your trusted ally in the digital realm. Let's embark on a journey to create web experiences that leave a lasting impression.",
      },
      
    ];

    
import React from 'react'
import HeroAnimation from '../components/HeroAnimation'
import { HeroContainer, HeroBg, HeroLeftContainer, Img, HeroRightContainer, HeroInnerContainer, TextLoop, Title, Span, SubTitle,SocialMediaIcons,SocialMediaIcon, MoreButton } from '../components/HeroLandingStyled'
import HeroImg from '../ificon.jpg'
import Typewriter from 'typewriter-effect';
import { HeroRoles } from '../Data/constants';

const HeroLanding = () => {
    return (
        <div id="about">
            <HeroContainer>
                <HeroBg>
                    <HeroAnimation />
                </HeroBg>
                <HeroInnerContainer >
                    <HeroLeftContainer id="Left">
                        <Title>Welcome To <br /> IF Technologies & Solutions </Title>
                        <TextLoop>
                            We are
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: HeroRoles.roles,
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <SubTitle>At IF Technologies & Solutions, we are more than just a web development company; we are your partners in digital success. With a proven track record of crafting exceptional online experiences, we bring your visions to life with cutting-edge technologies and unwavering dedication.</SubTitle>
                        <MoreButton href="#services" target='display'>More</MoreButton>
                    </HeroLeftContainer>

                    <HeroRightContainer id="Right">

                        <Img src={HeroImg} alt="hero-image" />
                    </HeroRightContainer>
                </HeroInnerContainer>

            </HeroContainer>
        </div>
    )
}

export default HeroLanding
import styled from "styled-components";

const Landing = (props) => {
    return(
        <Container>
            <Content>
                <Title>
                    IF Technologies & Solutions
                </Title>
                <Description>
                    At IF Technologies & Solutions, we are more than just a web development company; we are your partners in digital success. With a proven track record of crafting exceptional online experiences, we bring your visions to life with cutting-edge technologies and unwavering dedication.
                </Description>
                <LandingImage />
            </Content>
        </Container>

    );
};


const Container = styled.section`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100vh;
`;

const Content = styled.div`
    margin-bottom: 10vw;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px 40px;
    height: 100%;
`;


const LandingImage = styled.div`
    height: 100vh;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("/images/landing-background.jpg");
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 0.6;
`; 

const Description = styled.p`
    color: hsla(0, 0%, 95.3%, 1);
    font-size: 18px;
    margin: 0 0 24px;
    line-height: 1.5;
    letter-spacing: 1.5px;
    max-width: 50%;
`;

const Title = styled.h2`
    color: hsla(0, 0%, 95.3%, 1);
    font-size: 30px;
    margin: 0 0 24px;
    line-height: 1.5;
    letter-spacing: 1.5px;
`;




export default Landing;


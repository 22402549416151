import React from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from '../components/ServicesStyle'
import ServiceCard from '../components/Cards/ServiceCards'
import { services } from '../Data/constants'


const Services = ({openModal,setOpenModal}) => {
    const [toggle, setToggle] = useState('all');
    return (
        <Container id="services">
        <Wrapper>
            <Title>Services</Title>
            <Desc>
            IF Technologies & Solutions provides a diverse range of services tailored to meet your digital needs. Our expertise encompasses Web Development, crafting dynamic and interactive websites. We excel in E-Commerce Solutions, enabling businesses to establish and expand their online presence. Additionally, we offer Maintenance & Support services to ensure the seamless operation and longevity of your digital platforms. Our Consulting services provide valuable insights and strategic guidance for optimizing your online presence and achieving your business goals.
            </Desc>
            <CardContainer>
            {toggle === 'all' && services
                .map((service) => (
                <ServiceCard project={service} openModal={openModal} setOpenModal={setOpenModal}/>
                ))}
            {services
                .filter((item) => item.category == toggle)
                .map((service) => (
                <ServiceCard project={service} openModal={openModal} setOpenModal={setOpenModal}/>
                ))}
            </CardContainer>
        </Wrapper>
        </Container>
    )
}

export default Services